import { useRecoilValue } from 'recoil';
import { sum, flatten } from 'lodash';

import { tradesAtom, incomesAtom, accountAtom, exchangeAtom } from '../recoil/atoms';

export const income = (exchange) => {
  if (exchange === 'binance') {
    return 'income';
  }
  if (exchange === 'okx') {
    return 'balChg';
  }
  return `error`;
};
export const symbol = (exchange) => {
  if (exchange === 'binance') return 'symbol';
  if (exchange === 'okx') return 'symbol';
  return `error`;
};
export const nowbalance = (exchange) => {
  if (exchange === 'binance') return 'totalWalletBalance';
  if (exchange === 'okx') return 'totalEq';
  return `error`;
};
export const Upl = () => {
  const account = useRecoilValue(accountAtom);
  const exchange = useRecoilValue(exchangeAtom);
  if (exchange === 'binance') return account.totalUnrealizedProfit;
  if (exchange === 'okx') {
    if (!account) return `error`;
    // const ss=flatten(Object.values(account));
    // const tt=sum(ss.map((inc)=> inc.upl));
    const upl = sum(flatten(Object.values(account)).map((inc) => inc.upl));
    console.log(upl);
    return upl;
  }
  return `error`;
};
