import axios from 'axios';

import { BINANCE_HEADERS, getSignature, getUrl } from './utils';

export const getUserAccount = async (exchange) => {
  const timestamp = Date.now();

  const params = {
    timestamp,
    signature: getSignature(`timestamp=${timestamp}`)
  };

  const response = await axios.request({
    url: getUrl(exchange, 'account'),
    method: 'get',
    params,
    headers: BINANCE_HEADERS
  });
  // console.log(res);
  const res=response.data;
  if(exchange==='okx')
  {
    if(res.code==='0')return res.data[0];
  }
  return response.data[0];
  // return res;
};
