import React from 'react';
import { Grid } from '@mui/material';
import { sum, flatten } from 'lodash';
import { useRecoilValue } from 'recoil';
import { format } from 'date-fns';

import SummaryBox from '../../components/_dashboard/SummaryBox';
import { fCurrency, fNumber } from '../../utils/formatNumber';
import { tradesAtom, incomesAtom, accountAtom, exchangeAtom } from '../../recoil/atoms';
import { getPercentIncrease } from '../../utils/functions';
import * as ex from '../../utils/getexproperty';

const Summary = () => {
  const exchange = useRecoilValue(exchangeAtom);

  const trades = useRecoilValue(tradesAtom);
  const incomes = useRecoilValue(incomesAtom);
  const account = useRecoilValue(accountAtom);
  const today = format(new Date(), 'MM/dd/yyyy');
  const incomeToday = incomes[today];
  const tradesToday = trades[today];
  const tradesNumber = {};

  const income = ex.income(exchange);
  const balancestr = ex.nowbalance(exchange);
  const symbol = ex.symbol(exchange);
  const uplstr = ex.Upl();
  // const sss = flatten(Object.values(account));
  // const sss2 = flatten(Object.values(sss));
  console.log(balancestr);
  console.log(account);
  // console.log(sss);

  const profitToday = sum(incomeToday?.map((inc) => JSON.parse((inc[income])?inc[income]:null)));
 // const weekProfit = sum(flatten(Object.values(incomes)).map((inc) => JSON.parse((inc[income])?inc[income]:null)));
  const weekProfit =12;

  tradesToday?.forEach((trade) => {
    tradesNumber[trade[symbol]] = (tradesNumber[trade[symbol]] || 0) + 1;
  });
  const sortedPerformersOfToday = Object.entries(tradesNumber).sort(
    (prev, next) => prev[1] - next[1]
  );
  
  const nowbalance = fCurrency(Math.round(account[balancestr]));
  const mostTradedtoday = sortedPerformersOfToday?.pop()?.[0];
  const todayPercentIncrease = getPercentIncrease(profitToday, account[balancestr]);
  const weekPercentIncrease = getPercentIncrease(weekProfit, account[balancestr]);
  const unPnl = uplstr;
  const tradesNum = fNumber(tradesToday?.length);

  return (
    <>
      <Grid item xs={6} sm={4} md={2}>
        <SummaryBox
          text="Profit Today"
          total={todayPercentIncrease}
          subText={fCurrency(profitToday)}
          color="primary"
          backgroundColor="success"
        />
      </Grid>

      <Grid item xs={6} sm={4} md={2}>
        <SummaryBox
          text="Profit last 7 days"
          total={weekPercentIncrease}
          color="info"
          subText={fCurrency(weekProfit)}
          backgroundColor="info"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SummaryBox text="Unrealised PnL" total={unPnl} color="warning" backgroundColor="error" />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SummaryBox
          text="Most traded today"
          total={mostTradedtoday}
          color="error"
          backgroundColor="warning"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SummaryBox
          text="Trades today"
          total={tradesNum}
          color="warning"
          backgroundColor="secondary"
        />
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SummaryBox text="Balance" total={nowbalance} color="error" backgroundColor="primary" />
      </Grid>
    </>
  );
};

export default Summary;
