import { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { format } from 'date-fns';

// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
// recoil and services
import { accountAtom, incomesAtom, tradesAtom, updateTimeAtom, exchangeAtom } from './recoil/atoms';
import { getTradesOfTheWeek, getUserTradesOfTheDay,getUserTradesByDate } from './services/tradesServices';
import { getIncomesOfTheWeek, getUserIncomesOfTheDay } from './services/incomeServices';
import { getUserAccount } from './services/accountServices';

// ----------------------------------------------------------------------

const INTERVAL_FETCH = 60000; // 1 minute

const App = () => {
  const [intervalId, setIntervalId] = useState();
  const exchange = useRecoilValue(exchangeAtom);
  // const theccount = useRecoilValue(accountAtom);

  const setTradesRecoil = useSetRecoilState(tradesAtom);
  const setIncomesRecoil = useSetRecoilState(incomesAtom);
  const setAccountRecoil = useSetRecoilState(accountAtom);
  const setUpdateTimeRecoil = useSetRecoilState(updateTimeAtom);

  const getDailyData = (exchange) => {
    getUserAccount(exchange).then((account) => setAccountRecoil(account));
    getUserTradesByDate(exchange, format(new Date(), 'MM/dd/yyyy')).then((trades) =>
      setTradesRecoil((oldTrades) => ({
        ...oldTrades,
        [format(new Date(), 'MM/dd/yyyy')]: trades
      }))
    );
    getUserIncomesOfTheDay(exchange).then((incomes) =>
      setIncomesRecoil((oldIncomes) => ({
        ...oldIncomes,
        [format(new Date(), 'MM/dd/yyyy')]: incomes
      }))
    );

    setUpdateTimeRecoil(new Date());
    console.log('Updating your daily data... time: ', new Date());
  };

  const periodicallyFetchDailyData = () => {
    const id = setInterval(getDailyData(exchange), INTERVAL_FETCH);
    setIntervalId(id);
  };

  useEffect(() => {
    console.log('Fetching your weekly data');

    getTradesOfTheWeek(exchange).then((trades) => setTradesRecoil(trades));
    getIncomesOfTheWeek(exchange).then((incomes) => setIncomesRecoil(incomes));
    getUserAccount(exchange).then((account) => {
      setAccountRecoil(account);
      console.log('is ok!!!!!!!!!!');
    });
    setUpdateTimeRecoil(new Date());

    periodicallyFetchDailyData();
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
};

export default App;
